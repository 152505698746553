import React from 'react';
import External from './ExternalLayout';
import LoginComponent from 'components/login-component';

export default function Login(props) {
  return (
    <External>
      <LoginComponent history={props.history} />
    </External>
  );
}

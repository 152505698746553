import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { persistReducer, createMigrate } from 'redux-persist';
import recycleState from 'redux-recycle';
import storage from 'redux-persist/lib/storage';
import entitiesMiddleware from 'utils/middleware/entities';
import authentication, { authInitialState, actions } from './authentication';
import categories, { categoriesInitialState } from './categories';
import home, { homeInitialState } from './home';
import authors, { authorsInitialState } from './authors';
import books, { booksInitialState } from './books';
import player, { playerInitialDisplay } from './player';

const migrations = {
  0(state) {
    return {
      ...state,
    };
  },
  1(state) {
    return {
      ...state,
      books: {
        ...state.books,
        hasReader: {},
        readerData: {},
        readerEntities: {
          books: {},
          categories: {},
          readerImages: {},
        },
      },
    };
  },
};

const playerPersistConfig = {
  key: 'player',
  storage: storage,
  version: 0,
  blacklist: ['isPlaying'],
};

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  blacklist: ['player'],
  migrate: createMigrate(migrations, { debug: false }),
};

export const initialState = {
  authentication: authInitialState,
  categories: categoriesInitialState,
  home: homeInitialState,
  authors: authorsInitialState,
  books: booksInitialState,
  player: playerInitialDisplay,
};

const rootReducer = recycleState(
  combineReducers({
    authentication,
    categories,
    home,
    authors,
    books,
    player: persistReducer(playerPersistConfig, player),
  }),
  [String(actions.logout.fulfilled), String(actions.logout.rejected)]
);

function createStore(preloadedState = {}) {
  return configureStore({
    preloadedState,
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: [
      promiseMiddleware,
      entitiesMiddleware(),
      ...getDefaultMiddleware(),
      process.env.NODE_ENV !== 'production'
        ? require('redux-logger').default
        : undefined,
    ].filter(Boolean),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

export default createStore;

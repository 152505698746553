import React, { Component } from "react";
import { Link } from "react-router-dom";

import HeaderUser from './HeaderUser';
import HeaderSearch from "../search/HeaderSearch";
import clazbookslogo from "../../assets/clazbookslogo.png";
import { ReactComponent as Notifications } from "../../assets/icons/notifications.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.notifToggle = this.notifToggle.bind(this);
  }
  notifToggle() {
    document.body.classList.toggle("show-notification-tab");
  }
  render() {
  
    return (
      <div className="header">
        <div className="container header-content">
          <div className="header-left">
            <Link to="/">
              <img src={clazbookslogo} alt="Clazbooks Logo" />
            </Link>
          </div>
          {/*<HeaderSearch />*/}
          <div className="header-right">
            <HeaderUser />
            {/*<div className="header-notifs">
              <div
                className="standard-circle-button"
                onClick={this.notifToggle}
              >
                <Notifications />
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;

import http from 'utils/http';
import { getDataAndHeaders } from 'utils/helpers';
import normalize from 'utils/normalize';

export function login({ email, password }) {
  return http
    .post('/users/auth/sign_in', {
      email,
      password,
    })
    .then(res => {
      const { data, headers } = getDataAndHeaders(res);
      return {
        ...normalize(data),
        headers,
      };
    });
}

export function register({ name, email, password, os }) {
  return http.post('/users/auth', { name, email, password, os }).then(res => {
    const { data, headers } = getDataAndHeaders(res);
    return {
      ...normalize(data),
      headers,
    };
  });
}

export function logout() {
  return http.delete('users/auth/sign_out');
}

import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { includes } from 'lodash-es';

import Icon from 'components/Icon/Icon';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Browse } from '../../assets/icons/browseleft.svg';
import { ReactComponent as Favourites } from '../../assets/icons/favourites.svg';

const SideHeader = ({ location }) => {
  const link = location.pathname;
  return (
    <div className="side-header">
      <div className="side-header-section">
        {/*<h4 className="shs-discover">Discover</h4>*/}
        <div className="shs-section-links">
          <Link
            className={link === '/' ? 'active-shs-link fill-svg' : 'fill-svg'}
            to="/">
            <Home className="shs-svg" /> Inicio
          </Link>
          <Link
            className={
              link === '/browse' || includes(link, 'category')
                ? 'active-shs-link fill-svg'
                : 'fill-svg'
            }
            to="/browse">
            <Browse className="shs-svg" /> Buscar
          </Link>
          <Link
            className={
              link === '/favourites'
                ? 'active-shs-link no-fill-svg'
                : ' no-fill-svg'
            }
            to="/favourites">
            <Favourites className="shs-svg" /> Mis Favoritos
          </Link>
          <a
            href="https://apps.apple.com/us/app/clazbooks-libros/id1224261279"
            target="_blank"
            rel="noopener noreferrer">
            <Icon name="apple" className="shs-svg" /> App Store
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.clazbooks.clazbooks&hl=en"
            target="_blank"
            rel="noopener noreferrer">
            <Icon name="googleplay" className="shs-svg" /> Google Play
          </a>
          <a
            href="https://www.instagram.com/clazbooks"
            target="blank"
            rel="noopener noreferrer">
            <Icon name="instagram1" className="shs-svg" /> Instagram
          </a>
          <a
            href="https://www.youtube.com/channel/UC8mVb8KPJ0ev6ZtFCZM_K6w"
            target="_blank"
            rel="noopener noreferrer">
            <Icon name="youtube" className="shs-svg" /> Youtube
          </a>
        </div>
      </div>
      <div className="side-header-section">
        <div className="">
          
          <div>
            <p>
              5201 Blue Lagoon Drive. Floor 8 Miami, FL 33126 Clazbooks 2019
            </p>
          </div>
          {/*<Link
            className={
              link === "/playlists" || includes(link, "playlist")
                ? "active-shs-link fill-svg"
                : "fill-svg"
            }
            to="/playlists"
          >
            <Playlists className="shs-svg" /> Playlists
          </Link>*/}
          {/*<Link
            className={
              includes(link, "author")
                ? "active-shs-link no-fill-svg"
                : " no-fill-svg"
            }
            to="/authors"
          >
            <Authors className="shs-svg" /> Authors
          </Link>*/}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SideHeader);

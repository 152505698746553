import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import map from 'ramda/es/map';

import { actions, categoriesSelector } from 'modules/categories';

const categoriesList = map(category => {
  const { id, attributes } = category;
  const { name, s3ImageWeb } = attributes;
  return (
    <Link key={id} to={`category/${id}`} className="browse-item">
      <div
        className="browse-image"
        style={{
          backgroundImage: `url(${
            s3ImageWeb
          })`,
        }}
      />
      <p>{name}</p>
    </Link>
  );
});

function CategoriesPage({ getCategories, categories }) {
  React.useEffect(() => {
    getCategories();
  }, [getCategories]);
  const BrowseList = categoriesList(categories);
  return (
    <div className="standard-page">
      <h1>Buscar</h1>
      <div className="browse-grid">{BrowseList}</div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  {
    getCategories: actions.getCategories,
  }
)(CategoriesPage);

function mapStateToProps(state) {
  return {
    categories: categoriesSelector(state),
  };
}

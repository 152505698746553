import { createReducer } from 'redux-starter-kit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import {
  login as _login,
  logout as _logout,
  register as _register,
} from './api';
import { networkStatus } from 'utils/constants';

export const authInitialState = {
  loginStatus: networkStatus.notAsked,
  registerStatus: networkStatus.notAsked,
  logoutStatus: networkStatus.notAsked,
  accessToken: '',
  currentUserId: '',
  uid: '',
  client: '',
  expiry: '',
  user: {},
  loginError: null,
};

const login = createAsyncAction('LOGIN_USER', _login);
const logout = createAsyncAction('LOGOUT_USER', _logout);
const register = createAsyncAction('REGISTER', _register);

const reducer = createReducer(authInitialState, {
  [String(login.pending)]: state => {
    state.loginStatus = networkStatus.pending;
  },
  [String(login.fulfilled)]: (state, action) => {
    const { payload } = action;
    const { result, entities, headers } = payload;
    const user = entities.users[result];
    state.loginStatus = networkStatus.fulfilled;
    state.currentUserId = result;
    state.user = user;
    state.uid = headers.uid;
    state.expiry = headers.expiry;
    state.client = headers.client;
    state.accessToken = headers['access-token'];
  },
  [String(login.rejected)]: (state, action) => {
    const { payload } = action;
    state.loginStatus = networkStatus.rejected;
    state.loginError = payload;
  },
  [String(register.pending)]: state => {
    state.registerStatus = networkStatus.pending;
  },
  [String(register.fulfilled)]: (state, action) => {
    const { payload } = action;
    const { result, entities, headers } = payload;
    const user = entities.users[result];
    state.loginStatus = networkStatus.fulfilled;
    state.registerStatus = networkStatus.fulfilled;
    state.currentUserId = result;
    state.user = user;
    state.uid = headers.uid;
    state.expiry = headers.expiry;
    state.client = headers.client;
    state.accessToken = headers['access-token'];
  },
  [String(register.rejected)]: (state, action) => {
    const { payload } = action;
    state.registerStatus = networkStatus.rejected;
    state.loginError = payload;
  },
  [String(logout.pending)]: state => {
    state.logoutStatus = networkStatus.pending;
  },
  [String(logout.fulfilled)]: state => {
    state.logoutStatus = networkStatus.fulfilled;
  },
  [String(logout.rejected)]: state => {
    state.logoutStatus = networkStatus.rejected;
  },
});

export default reducer;

export const actions = {
  login,
  logout,
  register,
};

import nativeNormalize from 'json-api-normalizer';

const normalize = (json, opts = {}) => {
  const entities = nativeNormalize(json, opts);
  const result = Array.isArray(json.data)
    ? json.data.map(({ id }) => id)
    : json.data.id;
  const meta = json.meta;

  return { entities, result, meta };
};

export default normalize;

import React, { Component } from "react";

import DeleteAccountSection from "./DeleteAccountSection";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowright.svg";
import { ReactComponent as Details } from "../../assets/icons/details.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark.svg";
import profilephoto from "../../assets/samples/profilephoto.png";

class MobileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = { mode: "general", deleteAccount: false };
  }
  render() {
    const { mode, deleteAccount } = this.state;
    const { home } = this.props;
    return (
      <>
        {deleteAccount && (
          <DeleteAccountSection
            close={() => this.setState({ deleteAccount: false })}
          />
        )}
        <div className="mobile-menu-section">
          <div className="mobile-settings-header">
            <div className="phr-dets" onClick={home}>
              <ArrowRight className="flip" />
            </div>
            <div
              className="mms-big-pic"
              onClick={() => this.setState({ deleteAccount: true })}
            >
              <div
                style={{ backgroundImage: `url(${profilephoto})` }}
                className="mms-pic"
                alt=""
              />
              <Delete />
            </div>
            <div className="phr-dets">
              <Details />
            </div>
          </div>
          <div className="mobile-settings-options">
            <span
              className={mode === "general" ? "mso-active" : ""}
              onClick={() => this.setState({ mode: "general" })}
            >
              General
            </span>
            <span
              className={mode === "password" ? "mso-active" : ""}
              onClick={() => this.setState({ mode: "password" })}
            >
              Password
            </span>
          </div>
          <div className="mobile-settings-form h-form">
            {mode === "general" && (
              <>
                <div className="h-form-group">
                  <label>First name</label>
                  <input
                    type="text"
                    defaultValue="Maria"
                    placeholder="First Name"
                  />
                </div>
                <div className="h-form-group">
                  <label>Last name</label>
                  <input
                    type="text"
                    defaultValue="Chapman"
                    placeholder="Last Name"
                  />
                </div>
                <div className="h-form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    defaultValue="email@clazbooks.com"
                    placeholder="Email"
                  />
                </div>
                <div className="standard-button save-changes">
                  <Checkmark />Save Changes
                </div>
              </>
            )}
            {mode === "password" && (
              <>
                <div className="h-form-group">
                  <label>Password</label>
                  <input type="password" defaultValue="password" />
                </div>
                <div className="h-form-group">
                  <label>New password</label>
                  <input type="password" defaultValue="password" />
                </div>
                <div className="h-form-group">
                  <label>Repeat new password</label>
                  <input type="password" defaultValue="password" />
                </div>
                <div className="standard-button save-changes">
                  <Checkmark />Save Changes
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MobileSettings;

import http from 'utils/http';
import { getDataAndHeaders } from 'utils/helpers';
import normalize from 'utils/normalize';

export function getAuthors() {
  return http
    .get('/authors', {
      params: {},
    })
    .then(response => {
      const { data, headers } = getDataAndHeaders(response);
      return {
        ...normalize(data),
        headers,
      };
    });
}

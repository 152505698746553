import http from './http';
import { actions } from 'modules/authentication';

export default function createAuthInterceptor(store) {
  http.interceptors.request.use(
    config => {
      const { getState } = store;
      const { authentication } = getState();
      const { accessToken, client, uid } = authentication;
      if (accessToken) {
        config.headers['access-token'] = `${accessToken}`;
        config.headers.client = client;
        config.headers.uid = uid;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  http.interceptors.response.use(
    config => config,
    error => {
      if (error.response.status === 401) {
        store.dispatch(actions.logout());
      }
      throw error;
    }
  );
}

import { normalize as newNormalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import http from 'utils/http';
import { getDataAndHeaders } from 'utils/helpers';
import normalize from 'utils/normalize';

const booksSchema = new schema.Entity('books');
const categoriesSchema = new schema.Entity('categories', {
  books: [booksSchema],
});
const readerImagesSchema = new schema.Entity('readerImages');

const responseSchema = new schema.Object({
  data: {
    categories: [categoriesSchema],
    readerImages: [readerImagesSchema],
    recommendedBooks: [booksSchema],
  },
});

export function getBook(id) {
  return http.get(`/books/${id}`).then(response => {
    const { data, headers } = getDataAndHeaders(response);
    return {
      ...normalize(data),
      headers,
    };
  });
}

export function getBookReader(id) {
  return http
    .get(`/books/new_reader/${id}`, {
      params: {
        md_reader: true,
      },
    })
    .then(response => {
      const { data, headers } = getDataAndHeaders(response);
      return {
        ...newNormalize(camelizeKeys(data), responseSchema),
        headers,
      };
    });
}

export function favoriteBooks() {
  return http.get('/books/favorites').then(response => {
    const { data, headers } = getDataAndHeaders(response);
    return {
      ...normalize(data),
      headers,
    };
  });
}

export function addToFavs(id) {
  return http.post(`/books/${id}/add/favorite`).then(response => {
    const { data, headers } = getDataAndHeaders(response);
    return {
      ...normalize(data),
      headers,
    };
  });
}

export function removeFromFavs(id) {
  return http.delete(`/books/${id}/remove/favorite`).then(response => {
    const { data } = getDataAndHeaders(response);
    return data;
  });
}

import React, { Component } from "react";

import MobileHome from "./MobileHome";
import MobileSettings from "./MobileSettings";
import MobileNotifications from "./MobileNotifications";

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { page: "home" };
  }
  render() {
    const { close } = this.props;
    const { page } = this.state;
    return (
      <div className="mobile-menu">
        {page === "home" && (
          <MobileHome
            close={close}
            notif={() => this.setState({ page: "notif" })}
            settings={() => this.setState({ page: "settings" })}
          />
        )}
        {page === "settings" && (
          <MobileSettings home={() => this.setState({ page: "home" })} />
        )}
        {page === "notif" && (
          <MobileNotifications home={() => this.setState({ page: "home" })} />
        )}
      </div>
    );
  }
}

export default MobileMenu;

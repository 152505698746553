import React from 'react';
import External from './ExternalLayout';
import RegisterComponent from 'components/register-component';

export default function Login(props) {
  return (
    <External>
      <RegisterComponent history={props.history} />
    </External>
  );
}

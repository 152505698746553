import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { includes } from "lodash-es";

import MobileMenu from "../mobile/MobileMenu";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Browse } from "../../assets/icons/browseleft.svg";
import { ReactComponent as Favourites } from "../../assets/icons/favourites.svg";
import { ReactComponent as Playlists } from "../../assets/icons/playlists.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";

class MobileBottomHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { openMenu: false };
  }
  render() {
    const { openMenu } = this.state;
    const { location } = this.props;
    const link = location.pathname;
    return (
      <>
        {openMenu && (
          <MobileMenu close={() => this.setState({ openMenu: false })} />
        )}
        <div className="mobile-bottom-header">
          <Link
            className={
              link === "/" && !openMenu
                ? "mbh-item active-mbh-item"
                : "mbh-item"
            }
            to="/"
            onClick={() => this.setState({ openMenu: false })}
          >
            <Home />
            <span>Inicio</span>
          </Link>
          <Link
            className={
              (link === "/browse" || includes(link, "category")) && !openMenu
                ? "mbh-item active-mbh-item"
                : "mbh-item"
            }
            to="/browse"
            onClick={() => this.setState({ openMenu: false })}
          >
            <Browse />
            <span>Buscar</span>
          </Link>
          <Link
            className={
              link === "/favourites" && !openMenu
                ? "mbh-item active-mbh-item no-fill-svg"
                : "mbh-item no-fill-svg"
            }
            to="/favourites"
            onClick={() => this.setState({ openMenu: false })}
          >
            <Favourites />
            <span>Mis Favoritos</span>
          </Link>
          {/*<Link
            className={
              (link === "/playlists" || includes(link, "playlist")) && !openMenu
                ? "mbh-item active-mbh-item"
                : "mbh-item"
            }
            to="/playlists"
            onClick={() => this.setState({ openMenu: false })}
          >
            <Playlists />
            <span>Playlists</span>
          </Link>*/}
          <span
            className={openMenu ? "mbh-item active-mbh-item" : "mbh-item"}
            onClick={() => this.setState({ openMenu: !openMenu })}
          >
            <Menu />
            <span>Menu</span>
          </span>
        </div>
      </>
    );
  }
}

export default withRouter(MobileBottomHeader);

import React from 'react';
import { connect } from 'react-redux';
import { actions, homeSelector } from 'modules/home';
import { actions as categoriesActions, categoriesSelector } from 'modules/categories';
import pathOr from 'ramda/es/pathOr';

import ForYou from '../components/home/ForYou';
import BookGroup from '../components/home/BookGroup';

function HomePage({ getHome, getCategories, items, categories }) {
  React.useEffect(() => {
    getHome();
    getCategories();
  }, [getHome, getCategories]);
  return (
    <div className="standard-page">
      <ForYou categories={categories} />
      {items.map(item => {
        const books = pathOr([], ['relationships', 'books', 'data'], item);
        return (
          <BookGroup
            key={item.id}
            books={books.map(i => i.id)}
            title={item.attributes.name}
          />
        );
      })}
    </div>
  );
}

export default connect(
  mapStateToProps,
  {
    getHome: actions.getHome,
    getCategories: categoriesActions.getCategories,
  }
)(HomePage);

function mapStateToProps(state) {
  return {
    items: homeSelector(state),
    categories: categoriesSelector(state),
  };
}

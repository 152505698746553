import { createReducer } from 'redux-starter-kit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { createSelector } from 'reselect';
import path from 'ramda/es/path';
import { networkStatus } from 'utils/constants';
import { getAuthors as _getAuthors } from './api';

export const authorsInitialState = {
  getAuthorsStatus: networkStatus.notAsked,
  list: [],
  entities: {},
};

const getAuthors = createAsyncAction('GET_AUTHORS', _getAuthors);

const reducer = createReducer(authorsInitialState, {
  [String(getAuthors.pending)]: state => {
    state.getAuthorsStatus = networkStatus.pending;
  },
  [String(getAuthors.fulfilled)]: (state, action) => {
    const { payload } = action;
    state.getAuthorsStatus = networkStatus.fulfilled;
  },
  [String(getAuthors.rejected)]: state => {
    state.getAuthorsStatus = networkStatus.reject;
  },
  GET_ENTITIES: (state, action) => {
    if (action.payload.authors) {
      Object.assign(state.entities, action.payload.authors);
    }
  },
});

export default reducer;

export const actions = {
  getAuthors,
};

export const authorsEntitiesSelector = path(['authors', 'entities']);

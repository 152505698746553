import http from 'utils/http';
import { getDataAndHeaders } from 'utils/helpers';
import normalize from 'utils/normalize';

export function getHome() {
  return http
    .get('home', {
      params: {
        web: true,
      },
    })
    .then(res => {
      const { data, headers } = getDataAndHeaders(res);
      return {
        ...normalize(data),
        headers,
      };
    });
}

import { createReducer } from 'redux-starter-kit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import path from 'ramda/es/path';
import evolve from 'ramda/es/evolve';
import concat from 'ramda/es/concat';
import map from 'ramda/es/map';
import filter from 'ramda/es/filter';
import ifElse from 'ramda/es/ifElse';
import isEmpty from 'ramda/es/isEmpty';
import identity from 'ramda/es/identity';
import { networkStatus } from 'utils/constants';
import {
  getBook as _getBook,
  favoriteBooks as _favoriteBooks,
  addToFavs as _addToFavs,
  removeFromFavs as _removeFromFavs,
  getBookReader as _getBookReader,
} from './api';
import { tranformRelationships } from 'utils/entitites';

export const booksInitialState = {
  getBookStatus: networkStatus.notAsked,
  getFavoritesStatus: networkStatus.notAsked,
  hasDetail: {},
  hasReader: {},
  readerData: {},
  entities: {},
  favoriteList: [],
  readerEntities: {
    books: {},
    categories: {},
    readerImages: {},
  },
};

const addToFavs = createAsyncAction('ADD_TO_FAVS', _addToFavs, id => ({
  id,
  // extract: ['books'],
}));

const removeFromFavs = createAsyncAction(
  'REMOVE_FROM_FAVS',
  _removeFromFavs,
  (id, index) => ({ id, index })
);

const getFavorites = createAsyncAction('GET_FAVS', _favoriteBooks, () => ({
  extract: ['books'],
}));
const getBook = createAsyncAction('GET_BOOK', _getBook, id => ({
  extract: ['authors', 'categories'],
  id,
}));
const getBookReader = createAsyncAction('GET_READER', _getBookReader, id => ({
  id,
}));

const reducer = createReducer(booksInitialState, {
  [String(getBook.pending)]: (state, action) => {
    const { meta } = action;
    const { id } = meta;
    state.getBookStatus = networkStatus.pending;
    state.hasDetail[id] = networkStatus.pending;
  },
  [String(getBook.fulfilled)]: (state, action) => {
    const { payload, meta } = action;
    const { id } = meta;
    state.getBookStatus = networkStatus.fulfilled;
    state.hasDetail[id] = networkStatus.fulfilled;
    Object.assign(state.entities, payload.entities.books);
  },
  [String(getBook.rejected)]: (state, action) => {
    const { meta } = action;
    const { id } = meta;
    state.getBookStatus = networkStatus.rejected;
    state.hasDetail[id] = networkStatus.rejected;
  },

  [String(getFavorites.pending)]: (state, action) => {
    state.getFavoritesStatus = networkStatus.pending;
  },
  [String(getFavorites.fulfilled)]: (state, action) => {
    const { payload } = action;
    state.getFavoritesStatus = networkStatus.fulfilled;
    state.favoriteList = payload.result;
  },
  [String(getFavorites.rejected)]: (state, action) => {
    state.getFavoritesStatus = networkStatus.rejected;
  },

  [String(addToFavs.pending)]: (state, action) => {
    const {
      meta: { id },
    } = action;
    Object.assign(state.entities[id].attributes, {
      favorite: true,
    });
  },
  [String(addToFavs.fulfilled)]: (state, action) => {
    const {
      payload,
      meta: { id },
    } = action;
    state.favoriteList.unshift(id);
  },
  [String(addToFavs.rejected)]: (state, action) => {
    const {
      payload,
      meta: { id },
    } = action;
    const index = state.favoriteList.indexOf(id);
    state.favoriteList.splice(index, 1);
    Object.assign(state.entities[id].attributes, {
      favorite: false,
    });
  },

  [String(removeFromFavs.pending)]: (state, action) => {
    const {
      payload,
      meta: { id },
    } = action;
    const index = state.favoriteList.indexOf(id);
    state.favoriteList.splice(index, 1);
    Object.assign(state.entities[id].attributes, {
      favorite: false,
    });
  },
  [String(removeFromFavs.fulfilled)]: (state, action) => {
    const {
      payload,
      meta: { id },
    } = action;
    const index = state.favoriteList.indexOf(id);
    state.favoriteList.splice(index, 1);
  },
  [String(removeFromFavs.rejected)]: (state, action) => {
    const {
      payload,
      meta: { id, index },
    } = action;
    state.favoriteList.splice(index, 0, id);
    Object.assign(state.entities[id].attributes, {
      favorite: true,
    });
  },

  [String(getBookReader.pending)]: (state, action) => {
    const { meta } = action;
    state.hasReader[meta.id] = networkStatus.pending;
    state.readerData[meta.id] = state.readerData[meta.id] || {};
  },

  [String(getBookReader.fulfilled)]: (state, action) => {
    const { meta, payload } = action;
    state.hasReader[meta.id] = networkStatus.fulfilled;
    Object.assign(state.readerEntities, payload.entities);
    state.readerData[meta.id] = Object.assign(
      { version: payload.result.version },
      payload.result.data
    );
  },

  [String(getBookReader.rejected)]: (state, action) => {
    const { meta } = action;
    state.hasReader[meta.id] = networkStatus.rejected;
  },

  GET_ENTITIES: (state, action) => {
    if (action.payload.books) {
      Object.assign(state.entities, action.payload.books);
    }
  },
});

export default reducer;

export const actions = {
  getBook,
  getFavorites,
  addToFavs,
  removeFromFavs,
  getBookReader,
};

export const bookEntitiesSelector = path(['books', 'entities']);

export const getBookStatuses = path(['books', 'hasDetail']);

export const getBookStatus = (state, id) =>
  getBookStatuses(state)[id] || networkStatus.notAsked;

const _getBookById = (state, id) => bookEntitiesSelector(state)[id];

export const getBookById = createCachedSelector(_getBookById, book =>
  tranformRelationships(book)
)((_, id) => id);

export const favoritesIdsSelector = path(['books', 'favoriteList']);

export const getReaderStatuses = path(['books', 'hasReader']);

export const getReaderStatus = (state, id) =>
  getReaderStatuses(state)[id] || networkStatus.notAsked;

const getReaderEntity = entity =>
  path(concat(['books', 'readerEntities'], [entity]));

export const readerBookEntitesSelector = getReaderEntity('books');
export const readerCategoriesEntitiesSelector = getReaderEntity('categories');
export const readerImagesEntitiesSelector = getReaderEntity('readerImages');

export const readerBookEntitySelector = (state, id) =>
  readerBookEntitesSelector(state, id) || {};
export const readerCategoryEntitySelector = (state, id) =>
  readerCategoriesEntitiesSelector(state, id) || {};
export const readerImageEntitySelector = (state, id) =>
  readerImagesEntitiesSelector(state, id) || {};
export const _readerByIdSelector = (state, id) =>
  path(['books', 'readerData', id], state) || {};

export const readerByIdSelector = createCachedSelector(
  [
    readerBookEntitySelector,
    readerCategoryEntitySelector,
    readerImageEntitySelector,
    _readerByIdSelector,
  ],
  (books, categories, readerImages, reader) => {
    return ifElse(
      isEmpty,
      identity,
      evolve({
        categories: i => filter(Boolean, map(id => categories[id], i)),
        recommendedBooks: i => filter(Boolean, map(id => books[id], i)),
        readerImages: i => filter(Boolean, map(id => readerImages[id], i)),
      })
    )(reader);
  }
)((_, id) => id);

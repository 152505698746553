import http from 'utils/http';
import { getDataAndHeaders } from 'utils/helpers';
import normalize from 'utils/normalize';

export function getCategories() {
  return http.get('/categories').then(response => {
    const { data, headers } = getDataAndHeaders(response);
    return {
      ...normalize(data),
      headers,
    };
  });
}

export function getCategory(id) {
  return http.get(`/categories/${id}`).then(response => {
    const { data, headers } = getDataAndHeaders(response);
    return {
      ...normalize(data),
      headers,
    };
  });
}

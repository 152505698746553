import React from 'react';
import { Helmet } from 'react-helmet';
import Nav from '../components/nav';
import MobileNav from '../components/mobile-nav';
import HeadContent from '../components/head';
import Footer from '../components/footer';
import '../styles/styles/main.scss';

function ExternalLayout({ children }) {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      typeof window.executeMain === 'function' && window.executeMain();
    }
  }, []);
  return (
    <div className="template-wrapper">
      <Helmet>
        <script
          src={`${process.env.PUBLIC_URL}/vendors/jquery/jquery.js`}></script>

        <script
          src={`${process.env.PUBLIC_URL}/vendors/gsap/TweenMax.min.js`}></script>

        <script
          src={`${process.env.PUBLIC_URL}/vendors/in-view/jquery.inview.min.js`}></script>

        <script
          src={`${process.env.PUBLIC_URL}/vendors/scrollmagick/scrollmagic.js`}></script>
        <script
          src={`${process.env.PUBLIC_URL}/vendors/scrollmagick/scrollmagic-gsap.js`}></script>

        <script src={`${process.env.PUBLIC_URL}/js/main.js`}></script>
      </Helmet>
      <HeadContent />
      <Nav />
      <MobileNav />
      {children}
      <Footer />
    </div>
  );
}

export default ExternalLayout;

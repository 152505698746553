import React from 'react';
import P from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClickOutHandler from 'react-onclickout';

import { ReactComponent as DownArrow } from '../../assets/icons/downarrow.svg';
import { ReactComponent as Person } from '../../assets/icons/person.svg';
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { RetinaImagePath, isRetinaDisplay } from '../general/helpers';

const image = '/images/desktop/other/1440/photo%20profile.png';

function HeaderUser({ id, name }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="header-and-picture" onClick={() => setIsOpen(true)}>
      <div
        className="header-pic"
        style={{
          backgroundImage: `url(${
            isRetinaDisplay() ? RetinaImagePath(image) : image
          })`,
        }}
      />
      <div className="name-and-drop">
        <span className="header-name">{name}</span>
        <DownArrow className="name-drop-arrow" />
        {isOpen && (
          <ClickOutHandler onClickOut={() => setIsOpen(false)}>
            <div className="nad-dropdown" onClick={() => setIsOpen(false)}>
              <div className="nad-top">
                <Link to={`/profile/${id}`}>
                  <Person />
                  View my profile
                </Link>
              </div>
              <div className="nad-middle">
                <a
                  href="https://www.instagram.com/clazbooks"
                  target="blank"
                >
                    <Instagram />
                    Instagram
                </a>
                <a
                  href="https://www.youtube.com/channel/UC8mVb8KPJ0ev6ZtFCZM_K6w"
                  target="blank">
                    <Youtube />
                    Youtube
                </a>
              </div>
              <div className="nad-bottom">
                <Link to="/logout">
                  <Logout />
                  Logout
                </Link>
              </div>
            </div>
          </ClickOutHandler>
        )}
      </div>
    </div>
  );
}

HeaderUser.propTypes = {
  id: P.string.isRequired,
  name: P.string.isRequired,
  email: P.string,
};

export default connect(
  mapStateToProps,
)(HeaderUser);

function mapStateToProps(state) {
  const user = state.authentication.user;
  const { id } = user;
  const { email, name } = user.attributes;
  return {
    email,
    name,
    id,
  };
}

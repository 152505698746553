import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <a href="https://www.instagram.com/clazbooks/" className="instagram-btn">
        <span className="wrapper"></span>
        <span className="icon">
          <span className="icon-wrap">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.9375 0H13.0625C16.3412 0 19 2.65881 19 5.9375V13.0625C19 16.3412 16.3412 19 13.0625 19H5.9375C2.65881 19 0 16.3412 0 13.0625V5.9375C0 2.65881 2.65881 0 5.9375 0ZM9.5 4.75C6.87681 4.75 4.75 6.87681 4.75 9.5C4.75 12.1232 6.87681 14.25 9.5 14.25C12.1232 14.25 14.25 12.1232 14.25 9.5C14.25 6.87681 12.1232 4.75 9.5 4.75ZM9.5 12.4688C7.86363 12.4688 6.53125 11.1364 6.53125 9.5C6.53125 7.86244 7.86363 6.53125 9.5 6.53125C11.1364 6.53125 12.4688 7.86244 12.4688 9.5C12.4688 11.1364 11.1364 12.4688 9.5 12.4688ZM17.2188 13.0625C17.2188 15.3544 15.3544 17.2188 13.0625 17.2188H5.9375C3.64563 17.2188 1.78125 15.3544 1.78125 13.0625V5.9375C1.78125 3.64563 3.64563 1.78125 5.9375 1.78125H13.0625C15.3544 1.78125 17.2188 3.64563 17.2188 5.9375V13.0625ZM15.2392 4.39375C15.2392 4.74331 14.9558 5.02669 14.6062 5.02669C14.2567 5.02669 13.9733 4.74331 13.9733 4.39375C13.9733 4.04419 14.2567 3.76081 14.6062 3.76081C14.9558 3.76081 15.2392 4.04419 15.2392 4.39375Z"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="10.3697"
                  y1="25.8912"
                  x2="27.6304"
                  y2="8.63036"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FFC926" />
                  <stop offset="0.438599" stopColor="#FF5346" />
                  <stop offset="1" stopColor="#C536DE" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.9375 0H13.0625C16.3412 0 19 2.65881 19 5.9375V13.0625C19 16.3412 16.3412 19 13.0625 19H5.9375C2.65881 19 0 16.3412 0 13.0625V5.9375C0 2.65881 2.65881 0 5.9375 0ZM9.5 4.75C6.87681 4.75 4.75 6.87681 4.75 9.5C4.75 12.1232 6.87681 14.25 9.5 14.25C12.1232 14.25 14.25 12.1232 14.25 9.5C14.25 6.87681 12.1232 4.75 9.5 4.75ZM9.5 12.4688C7.86363 12.4688 6.53125 11.1364 6.53125 9.5C6.53125 7.86244 7.86363 6.53125 9.5 6.53125C11.1364 6.53125 12.4688 7.86244 12.4688 9.5C12.4688 11.1364 11.1364 12.4688 9.5 12.4688ZM17.2188 13.0625C17.2188 15.3544 15.3544 17.2188 13.0625 17.2188H5.9375C3.64563 17.2188 1.78125 15.3544 1.78125 13.0625V5.9375C1.78125 3.64563 3.64563 1.78125 5.9375 1.78125H13.0625C15.3544 1.78125 17.2188 3.64563 17.2188 5.9375V13.0625ZM15.2392 4.39375C15.2392 4.74331 14.9558 5.02669 14.6062 5.02669C14.2567 5.02669 13.9733 4.74331 13.9733 4.39375C13.9733 4.04419 14.2567 3.76081 14.6062 3.76081C14.9558 3.76081 15.2392 4.04419 15.2392 4.39375Z"
                fill="#fff"
              />
            </svg>
          </span>
        </span>
        <span className="label">Instagram</span>
      </a>

      <div className="footer-copyright">(c) 2019 Clazbooks</div>

      <a href="/" className="footer-logo">
        <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="" />
      </a>
    </footer>
  );
}

import React, { Component } from "react";
import { times } from "lodash-es";
import { Link } from "react-router-dom";

import { ReactComponent as UpdateIcon } from "../../assets/icons/update.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowright.svg";
import { RetinaImagePath, isRetinaDisplay } from "../general/helpers";

const cover = "/images/desktop/other/1440/book-cover.png";

const Event = {
  title: "Created new playlist <a href='#'>&quot;Autobiography&quot;</a>",
  time: "Just now",
  image: isRetinaDisplay() ? RetinaImagePath(cover) : cover
};

const Update = {
  title: "Clazbooks Privacy Policy Updates",
  icon: UpdateIcon,
  time: "1 week ago",
  link: "/page"
};

class MobileNotifications extends Component {
  render() {
    const { home } = this.props;
    const Events = times(3, n => (
      <div className="notification-item">
        <div className="nitem-left">
          <div
            className="nitem-title"
            dangerouslySetInnerHTML={{ __html: Event.title }}
          />
          <div className="nitem-time">{Event.time}</div>
        </div>
        <div
          className="nitem-cover"
          style={{ backgroundImage: `url(${Event.image})` }}
        />
      </div>
    ));
    const Updates = times(2, n => (
      <div className="notification-update">
        <UpdateIcon />
        <div className="nitem-left">
          <div
            className="nitem-title"
            dangerouslySetInnerHTML={{ __html: Update.title }}
          />
          <div className="nitem-time">{Update.time}</div>
        </div>
        <Link to={Update.link} className="nitem-link">
          View
        </Link>
      </div>
    ));
    return (
      <div className="mobile-menu-section">
        <div className="mobile-settings-header mn-header">
          <div className="phr-dets" onClick={home}>
            <ArrowRight className="flip" />
          </div>
          <h2 className="notif-title">Notifications</h2>
          <div />
        </div>
        <div className="notifications-tab">
          <div className="notif-items">
            {Events}
            {Updates}
          </div>
        </div>
      </div>
    );
  }
}

export default MobileNotifications;

import React from 'react';
import { Route } from 'react-router-dom';
import Header from 'components/general/Header';
import SideHeader from 'components/general/SideHeader';
import MobileBottomHeader from 'components/general/MobileBottomHeader';
import Player from 'components/general/Player';
import HomePage from 'pages/HomePage';
import Browse from 'pages/Browse';
import '../styles/index.scss';

const CategoryPage = React.lazy(() =>
  import(/* webpackChunkName: "CategoryPage" */ 'pages/CategoryPage')
);
const AuthorPage = React.lazy(() =>
  import(/* webpackChunkName: "AuthorPage" */ 'pages/AuthorPage')
);
const ProfilePage = React.lazy(() =>
  import(/* webpackChunkName: "ProfilePage" */ 'pages/ProfilePage')
);
const Favourites = React.lazy(() =>
  import(/* webpackChunkName: "Favourites" */ 'pages/Favourites')
);
const BookPage = React.lazy(() =>
  import(/* webpackChunkName: "BookPage" */ 'pages/BookPage')
);
const MyPlaylists = React.lazy(() =>
  import(/* webpackChunkName: "MyPlaylists" */ 'pages/MyPlaylists')
);
const NewPlaylist = React.lazy(() =>
  import(/* webpackChunkName: "NewPlaylist" */ 'pages/NewPlaylist')
);
const ViewPlaylist = React.lazy(() =>
  import(/* webpackChunkName: "ViewPlaylist" */ 'pages/ViewPlaylist')
);
const AuthorPageFull = React.lazy(() =>
  import(/* webpackChunkName: "AuthorPageFull" */ 'pages/AuthorPageFull')
);

export default function AuthLayout() {
  return (
    <React.Fragment>
      {/*<NotificationTab />
      <Notifications />*/}
      <Header />
      <div className="app-content container">
        <SideHeader />
        <Route exact path="/" component={HomePage} />
        <Route path="/browse" component={Browse} />
        <Route path="/category/:id" component={CategoryPage} />
        <Route path="/favourites" component={Favourites} />
        <Route path="/book/:id" component={BookPage} />
        <Route path="/profile" component={ProfilePage} />
        {/*
          <Route path="/authors" component={AuthorPage} />
          <Route exact path="/authors/:id" component={AuthorPage} />
          <Route exact path="/author/:id/all" component={AuthorPageFull} />
          <Route path="/playlists" component={MyPlaylists} />
          <Route path="/newplaylist" component={NewPlaylist} />
          <Route exact path="/playlist/:id" component={ViewPlaylist} />*/}
      </div>
      <Player />
      <MobileBottomHeader />
    </React.Fragment>
  );
}

import { createReducer } from 'redux-starter-kit';
import { createAction } from 'redux-promise-middleware-actions';

export const playerInitialDisplay = {
  isPlaying: false,
  activeTrackId: '',
  playlist: [],
};

const setPlay = createAction('SET_PLAY', state => state);
const setActiveTrackId = createAction('SET_ACTIVE_TRACK', id => id);
const setTrackInPlaylist = createAction(
  'SET_TRACK_IN_PLAYLIST',
  trackId => trackId
);

const reducer = createReducer(playerInitialDisplay, {
  [String(setPlay)]: (state, action) => {
    state.isPlaying = action.payload;
  },
  [String(setActiveTrackId)]: (state, action) => {
    state.activeTrackId = action.payload;
  },
  [String(setTrackInPlaylist)]: (state, action) => {
    state.playlist.push(action.payload);
  },
});

export default reducer;

export const actions = {
  setPlay,
  setActiveTrackId,
  activateTrack,
  addTrackToPlaylist,
  rotatePlaylist,
};

function addTrackToPlaylist(track) {
  return function $addTrackToPlaylist(dispatch, getState) {
    const { player } = getState();
    const { playlist = [] } = player;

    if (!playlist.includes(track)) {
      dispatch(setTrackInPlaylist(track));
    }
  };
}

function activateTrack(track) {
  return function $activateTrack(dispatch, getState) {
    const { player } = getState();
    const {
      playlist = [],
      activeTrackId,
      isPlaying: isCurrentlyPlaying,
    } = player;
    const isPlaying = !isSameTrackAndPlaying(
      activeTrackId,
      track,
      isCurrentlyPlaying
    );

    if (!track) {
      return;
    }

    dispatch(setPlay(isPlaying));
    dispatch(setActiveTrackId(track));

    if (!playlist.includes(track)) {
      dispatch(setTrackInPlaylist(track));
    }
  };
}

function rotatePlaylist(direction = 1) {
  return function $rotatePlaylist(dispatch, getState) {
    const { player } = getState();
    const { playlist, activeTrackId } = player;
    const { length } = playlist;
    const activeIndex = playlist.findIndex(i => i === activeTrackId);
    let nextIndex;
    if (!length || length === 1) {
      return;
    }
    if (direction === 1) {
      nextIndex = (activeIndex + 1) % length;
    }
    if (direction === -1) {
      nextIndex = (activeIndex - 1 + length) % length;
    }

    dispatch(activateTrack(playlist[nextIndex]));
  };
}

function isSameTrackAndPlaying(activeTrackId, trackId, isPlaying) {
  return activeTrackId && isPlaying && activeTrackId === trackId;
}

import React from 'react';

export default function Icon({ name, className }) {
  return (
    <svg className={`icon icon-${name} ${className}`}>
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
}

Icon.defaultProps = {
  className: '',
};

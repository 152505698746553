import React from 'react';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { throttle } from 'throttle-debounce';
import useMedia from 'use-media';

import { ReactComponent as RightArrow } from '../../assets/icons/rightarrow.svg';
import { isRetinaDisplay } from '../general/helpers';

const styles = {
  slideStyle: {
    width: '230px',
    marginRight: '2px',
    marginLeft: '2px',
  },
};

function ForYou({ categories }) {
  const [currentIndex, setIndex] = React.useState(0);
  const isMobile = useMedia({ maxWidth: 768 });
  const container = React.useRef();
  const length = categories.length;
  const itemWidth = (!isMobile ? 220 : 80) + 4;
  const totalItemWidth = length * itemWidth;
  let containerWidth = 0;
  let totalindex = 1;
  if (container.current) {
    containerWidth = container.current.offsetWidth;
  }
  totalindex = Math.floor(containerWidth ? totalItemWidth / containerWidth : 0);

  React.useEffect(() => {
    function onResize(e) {
      setIndex(0);
    }
    const throttled = throttle(300, onResize);
    window.addEventListener('resize', throttled);
    return () => {
      throttled.cancel();
      window.removeEventListener('resize', throttled);
    };
  });
  return (
    <div className="standard-section" ref={container}>
      <div className="for-you-header">
        <h1>Categorias</h1>
        <div className="fyh-fb my-arrows">
          <div
            className="standard-circle-button standard-circle-button-circle flip-svg"
            onClick={() => {
              currentIndex > 0 && setIndex(c => c - 1);
            }}>
            <RightArrow />
          </div>
          <div
            className="standard-circle-button standard-circle-button-circle"
            onClick={() => {
              currentIndex < totalindex && setIndex(c => c + 1);
            }}>
            <RightArrow />
          </div>
        </div>
      </div>

      <SwipeableViews
        index={currentIndex}
        slideStyle={Object.assign({}, styles.slideStyle, { width: !isMobile ? '230px' : '80px' })}
        onChangeIndex={i => {
          if(i > 0 && i < totalindex) {
            setIndex(i);
          } else {
            setIndex(0);
          }
        }}
      >
        {categories.map(category => {
          const { id, attributes } = category;
          const { name, s3ImageWeb } = attributes || category;
          return (
            <Link key={id} to={`/category/${id}`} className="for-you-item">
              <div
                className="fyi-image"
                style={{
                  backgroundImage: `url(${
                    isRetinaDisplay() ? s3ImageWeb : s3ImageWeb
                  })`,
                }}
              />
              <p>{name}</p>
            </Link>
          );
        })}
      </SwipeableViews>
    </div>
  );
}

export default ForYou;

ForYou.defaultProps = {
  categories: [],
};

import React, { Component } from "react";
import classNames from "classnames";

import SearchResults from "../home/SearchResults";
import { ReactComponent as Browse } from "../../assets/icons/browse.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

class HeaderSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { search: "", searchOpen: false };
  }
  render() {
    const { search, searchOpen } = this.state;
    return (
      <div
        className={classNames("header-search", {
          "header-search-active": !!search,
          "header-search-extend": searchOpen
        })}
      >
        <input
          type="text"
          className="header-search-input"
          placeholder="Search books by name..."
          id="search-input"
          onChange={e => this.setState({ search: e.target.value })}
          onFocus={() => this.setState({ searchOpen: true })}
        />
        <Browse className="hs-browse-icon" />
        <Close
          className="hs-close-icon"
          onClick={() => {
            this.setState({ search: "", searchOpen: false });
            document.getElementById("search-input").value = "";
          }}
        />
        <SearchResults />
      </div>
    );
  }
}

export default HeaderSearch;

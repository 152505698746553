import lensProp from 'ramda/es/lensProp';
import compose from 'ramda/es/compose';
import pathOr from 'ramda/es/pathOr';
import map from 'ramda/es/map';
import ifElse from 'ramda/es/ifElse';
import prop from 'ramda/es/prop';
import over from 'ramda/es/over';
import always from 'ramda/es/always';
import { isUndefined } from './helpers';

const isArray = x => Array.isArray(x);

const relationshipLens = lensProp('relationships');
const processRelationships = map(
  compose(
    ifElse(isArray, map(prop('id')), prop('id')),
    pathOr([], ['data'])
  )
);

export const tranformRelationships = ifElse(
  isUndefined,
  always({}),
  over(relationshipLens, processRelationships)
);

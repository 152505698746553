import React from 'react';
import P from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/authentication';

function LogoutPage({ logout }) {
  React.useEffect(() => {
    logout();
  }, [logout]);
  return <h1>Logout</h1>;
}

LogoutPage.propTypes = {
  logout: P.func.isRequired,
};

export default connect(
  null,
  {
    logout: actions.logout,
  }
)(LogoutPage);

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import LogoutPage from 'pages/LogoutPage';
import PrivateRoute from 'components/privateRoute';
import ScrollToTop from './components/general/ScrollToTop';
import AuthLayout from 'pages/AuthLayout';

import LoginFull from './pages/Login';
import RegisterFull from './pages/Register';

// const LoginFull = React.lazy(() => import('./pages/Login'));

function App() {
  return (
    <React.Suspense fallback={<h1>Cargando...</h1>}>
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clazbooks</title>
          <meta
            name="description"
            content="Discover and listen to the most popular audio books"
          />
        </Helmet>
        <ScrollToTop>
          <div className="app-body" id="app-root">
            <Switch>
              <Route path="/login" component={LoginFull} />
              <Route path="/register" component={RegisterFull} />
              <PrivateRoute path="/logout" component={LogoutPage} />
              <PrivateRoute path="/" component={AuthLayout} />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    </React.Suspense>
  );
}

export default App;

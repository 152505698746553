import { createReducer } from 'redux-starter-kit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { createSelector } from 'reselect';
import path from 'ramda/es/path';
import values from 'ramda/es/values';
import compose from 'ramda/es/compose';
import sortBy from 'ramda/es/sortBy';
import { networkStatus } from 'utils/constants';
import { getHome as _getHome } from './api';
import { bookEntitiesSelector } from '../books';

export const homeInitialState = {
  getHomeStatus: networkStatus.notAsked,
  list: [],
  entities: {},
};

const getHome = createAsyncAction('GET_HOME', _getHome, () => ({
  extract: ['books'],
}));

const reducer = createReducer(homeInitialState, {
  [String(getHome.pending)]: state => {
    state.getHomeStatus = networkStatus.pending;
  },
  [String(getHome.fulfilled)]: (state, action) => {
    const { payload } = action;
    state.getHomeStatus = networkStatus.fulfilled;
    state.entities = payload.entities.webHomes;
  },
  [String(getHome.rejected)]: state => {
    state.getHomeStatus = networkStatus.rejected;
  },
});

export default reducer;

export const actions = {
  getHome,
};

const fn = compose(
  sortBy(path(['attributes', 'position'])),
  values
);

export const homeEntitiesSelector = path(['home', 'entities']);

export const homeSelector = createSelector(
  [homeEntitiesSelector],
  fn
);

import React from "react";
import { map } from "lodash-es";
import { Link } from "react-router-dom";

import { ReactComponent as Browse } from "../../assets/icons/browseleft.svg";
import { ReactComponent as Playlists } from "../../assets/icons/playlists.svg";

const myResults = [
  {
    type: "book",
    title: "Karate Chop: stories",
    url: "/"
  },
  {
    type: "book",
    title: "Karate Chop: stories",
    url: "/"
  },
  {
    type: "book",
    title: "Karate Chop: stories",
    url: "/"
  },
  {
    type: "playlist",
    title: "Karate Chop: stories",
    url: "/"
  }
];

const allResults = myResults;

const SearchResults = () => {
  const MyResults = map(myResults, (r, index) => (
    <Link className="result" to={r.url} key={`mr${index}`}>
      {r.type === "book" && <Browse />}
      {r.type === "playlist" && <Playlists />}
      <span>{r.title}</span>
    </Link>
  ));
  const AllResults = map(myResults, (r, index) => (
    <Link to="#" className="result" key={`mr${index}`}>
      {r.type === "book" && <Browse />}
      {r.type === "playlist" && <Playlists />}
      <span>{r.title}</span>
    </Link>
  ));
  return (
    <div className="search-results">
      <div className="search-results-section">
        <span className="srs-title">My Library</span>
        <div className="srs-list">{MyResults}</div>
      </div>
      <div className="search-results-section">
        <span className="srs-title">Search Results: {allResults.length}</span>
        <div className="srs-list">{AllResults}</div>
      </div>
    </div>
  );
};

export default SearchResults;

import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

// import { ReactComponent as Wave } from '../../assets/icons/wave.svg';
import Icon from 'components/Icon/Icon';
import { ReactComponent as Previous } from '../../assets/icons/previous.svg';
import { ReactComponent as Pause } from '../../assets/icons/pause.svg';
import { ReactComponent as Play } from '../../assets/icons/play.svg';
import { ReactComponent as PlayWhite } from '../../assets/icons/playw.svg';
import { ReactComponent as Next } from '../../assets/icons/next.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowup.svg';
import { ReactComponent as Details } from '../../assets/icons/details.svg';
import darkread from '../../assets/icons/darkread.svg';
import { bookEntitiesSelector } from 'modules/books';
import { convertTime } from 'utils/timeManipulation';
import { actions } from 'modules/player';

const ua = navigator.userAgent;
// const isSafari = ua.match(/safari/i) && !ua.match(/chrome/i);

const NETWORK_STATE = {
  NETWORK_EMPTY: 0,
  NETWORK_IDLE: 1,
  NETWORK_LOADING: 2,
  NETWORK_NO_SOURCE: 3,
};

const READY_STATE = {
  HAVE_NOTHING: 0,
  HAVE_METADATA: 1,
  HAVE_CURRENT_DATA: 2,
  HAVE_FUTURE_DATA: 3,
  HAVE_ENOUGH_DATA: 4,
};

function calculateLoading(buffered, duration) {
  let total = 0;
  if (duration > 0) {
    for (var i = 0; i < buffered.length; i++) {
      total = (buffered.end(buffered.length - 1 - i) / duration) * 100;
    }
  }
  return total.toFixed(0);
}

function Player({
  books,
  currentTrack,
  isPlaying,
  togglePlayTrack,
  playlist,
  onActivateTrack,
  rotatePlaylist,
}) {
  const [readyToPlay, setReadyToPlay] = React.useState(false);
  const [currentTime, setCurrent] = React.useState(0);
  const [networkState, setNetworkState] = React.useState(
    NETWORK_STATE.NETWORK_EMPTY
  );
  const [readyState, setReadyState] = React.useState(READY_STATE.HAVE_NOTHING);
  const [loadProgress, setLoadProgress] = React.useState(0);

  const audioElement = React.useRef();
  const element = audioElement.current;
  const [open, setOpen] = React.useState(false);
  const book = books[currentTrack] || {};
  const { attributes = {}, id } = book;
  const {
    audioUrl,
    audioDuration,
    name,
    mediumImageUrl,
    authorName,
  } = attributes;

  const trackProgress = (currentTime / Number(audioDuration)) * 100;

  function updateProgress(event) {
    const { currentTime } = event.target;
    const normalizedCurrent = Math.floor(currentTime);
    setCurrent(normalizedCurrent);
  }

  const onEndedTrack = React.useCallback(() => {
    togglePlayTrack(false);
    setCurrent(0);
  }, [togglePlayTrack]);

  const onPlayTrack = React.useCallback(() => {
    togglePlayTrack(true);
  }, [togglePlayTrack]);

  const onPauseTrack = React.useCallback(() => {
    togglePlayTrack(false);
  }, [togglePlayTrack]);

  const onProgress = React.useCallback(
    e => {
      const { networkState, readyState, buffered, duration } = e.target;
      const parsedDuration = !isNaN(duration) ? duration : 0;
      setNetworkState(networkState);
      setReadyState(readyState);
      setLoadProgress(calculateLoading(buffered, parsedDuration) || 0);
    },
    [setNetworkState, setReadyState, setLoadProgress]
  );

  function togglePlay(isPlaying) {
    if (!isPlaying) {
      element.play();
    } else {
      element.pause();
    }
  }

  React.useEffect(() => {
    function unlock() {
      if (element) {
        const promise = element.play();
        if (promise) {
          promise.then(
            () => {
              document.body.removeEventListener('touchstart', unlock);
              document.body.removeEventListener('mousedown', unlock);
              document.body.removeEventListener('touchend', unlock);
              setReadyToPlay(true);
            },
            e => {
              console.warn(e);
            }
          );
        } else {
          setReadyToPlay(true);
        }
      }
    }
    document.body.addEventListener('touchstart', unlock, false);
    document.body.addEventListener('mousedown', unlock, false);
    document.body.addEventListener('touchend', unlock, false);
    return () => {
      document.body.removeEventListener('touchstart', unlock);
      document.body.removeEventListener('mousedown', unlock);
      document.body.removeEventListener('touchend', unlock);
    };
  }, [element]);

  React.useEffect(() => {
    if (readyToPlay && element) {
      element.addEventListener('play', onPlayTrack);
      element.addEventListener('pause', onPauseTrack);
      element.addEventListener('ended', onEndedTrack);
      element.addEventListener('stalled', onEndedTrack);
      element.addEventListener('timeupdate', updateProgress);

      element.addEventListener('loadstart', onProgress);
      element.addEventListener('loadeddata', onProgress);
      element.addEventListener('progress', onProgress);
      element.addEventListener('canplay', onProgress);
      element.addEventListener('canplaythrough', onProgress);
    }
    return () => {
      if (readyToPlay && element) {
        element.removeEventListener('play', onPlayTrack);
        element.removeEventListener('pause', onPauseTrack);
        element.removeEventListener('ended', onEndedTrack);
        element.removeEventListener('stalled', onEndedTrack);
        element.removeEventListener('timeupdate', updateProgress);

        element.removeEventListener('loadstart', onProgress);
        element.removeEventListener('loadeddata', onProgress);
        element.removeEventListener('progress', onProgress);
        element.removeEventListener('canplay', onProgress);
        element.removeEventListener('canplaythrough', onProgress);
      }
    };
  }, [
    id,
    element,
    readyToPlay,
    onEndedTrack,
    onPlayTrack,
    onPauseTrack,
    updateProgress,
    onProgress,
  ]);

  React.useEffect(() => {
    if (isPlaying) {
      if (element) {
        const promise = element.play();
        if (promise) {
          promise.then(
            e => {},
            e => {
              console.warn(e);
              togglePlayTrack(false);
            }
          );
        }
      }
    } else {
      if (element) {
        element.pause();
      }
    }
  }, [isPlaying, element, togglePlayTrack, id])

  const audioPlayerUrl = !readyToPlay
    ? process.env.PUBLIC_URL + '/silence.mp3'
    : audioUrl;

  return (
    <React.Fragment>
      <audio
        id="audio"
        ref={audioElement}
        src={audioPlayerUrl}
        preload="metadata"
        style={{ display: 'none' }}
      />
      {book.id ? (
        <div className={classNames('player', { 'player-open': open })}>
          <div className="player-mobile-hover-section">
            <ArrowUp className="arrowup flip" onClick={() => setOpen(false)} />
            <div className="phs-content">
              <div className="phs-cover-details">
                <div
                  className="phs-cover"
                  style={{ backgroundImage: `url(${mediumImageUrl})` }}
                />
                <div className="phs-details">
                  <p className="phs-title">{name}</p>
                  <p className="phs-author">{authorName}</p>
                </div>
              </div>
              <Link to={`/book/${id}`} className="phs-link">
                <img src={darkread} alt="" />
                <Details />
              </Link>
            </div>
          </div>
          <div className="player-hover-section">
            <ArrowUp
              className="arrowup"
              id="opener"
              onClick={() => setOpen(open => !open)}
            />
            <div className="phs-content">
              <div className="phs-cover-details">
                <div
                  className="phs-cover"
                  style={{ backgroundImage: `url(${mediumImageUrl})` }}
                />
                <div className="phs-details">
                  <p className="phs-title">{name}</p>
                  <p className="phs-author">{authorName}</p>
                </div>
              </div>
              <Link to={`/book/${id}`} className="phs-link">
                <img src={darkread} alt="" />
              </Link>
            </div>
          </div>
          <div className="player-preview">
            <div
              className="pp-top"
              onClick={() => {
                if (window.innerWidth < 769) {
                  setOpen(true);
                }
              }}>
              <div
                className="pp-mobile-cover"
                style={{ backgroundImage: `url(${mediumImageUrl})` }}
              />
              <span className="pp-active-title">{name}</span>
              {networkState === NETWORK_STATE.NETWORK_LOADING
                ? <span className="pp-progress">{loadProgress}%</span>
                : null}
            </div>
            <div className="pp-bottom">
              <span className="pp-time">{convertTime(currentTime)}</span>
              <div className="pp-navs">
                <Previous
                  className="pp-nav-prev"
                  onClick={() => rotatePlaylist(-1)}
                />
                <button
                  style={{
                    WebkitAppearance: 'none',
                    background: 'transparent',
                    border: 0,
                  }}
                  type="button"
                  onClick={() => togglePlay(isPlaying)}>
                  <CircularProgressbarWithChildren
                    value={trackProgress}
                    styles={buildStyles({
                      rotation: 0,
                      strokeLinecap: 'round',
                      pathTransitionDuration: 0.5,
                      strokeWidth: '4px',
                      pathColor: '#30A3FF',
                      trailColor: '#ffffff',
                      backgroundColor: '#1E1F20',
                    })}>
                    <div
                      className={`standard-circle-button ${
                        isPlaying ? 'white-pause' : 'white-play'
                      }`}>
                      <Icon name={isPlaying ? 'pause' : 'play'} />
                    </div>
                  </CircularProgressbarWithChildren>
                </button>
                <Next onClick={() => rotatePlaylist(1)} />
              </div>
              <span className="pp-time">{convertTime(audioDuration)}</span>
            </div>
          </div>
          <div className="player-open-section">
            <div className="pos-divider" />
            <div className="chapter-list">
              {playlist.map(track => {
                const book = books[track] || {};
                const { attributes = {}, id } = book;
                const { audioDuration, name } = attributes;
                return (
                  <Chapter
                    key={track}
                    currentTrackPlaying={currentTrack}
                    togglePlay={togglePlay}
                    onActivateTrack={onActivateTrack}
                    id={id}
                    name={name}
                    isPlaying={isPlaying}
                    trackProgress={trackProgress}
                    duration={convertTime(audioDuration)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default connect(
  mapStateToProps,
  {
    togglePlayTrack: actions.setPlay,
    onActivateTrack: actions.activateTrack,
    rotatePlaylist: actions.rotatePlaylist,
  }
)(Player);

function mapStateToProps(state) {
  const books = bookEntitiesSelector(state);
  const currentTrack = state.player.activeTrackId;
  const { isPlaying, playlist } = state.player;
  return {
    books,
    currentTrack,
    isPlaying,
    playlist,
  };
}

function Chapter({
  id,
  name,
  currentTrackPlaying,
  num,
  isPlaying,
  togglePlay,
  trackProgress,
  onActivateTrack,
  duration,
}) {
  return (
    <div
      onClick={() => onActivateTrack(id)}
      className={classNames('chapter', {
        'active-chapter': currentTrackPlaying === id,
      })}
      key={`chapter${num}`}>
      <div className="chapter-left">
        {currentTrackPlaying === id ? (
          <CircularProgressbarWithChildren
            value={trackProgress}
            styles={buildStyles({
              rotation: 0,
              strokeLinecap: 'round',
              strokeWidth: '4px',
              pathTransitionDuration: 0.5,
              pathColor: '#30A3FF',
              trailColor: '#ffffff',
              backgroundColor: '#1E1F20',
            })}>
            <div
              className={`standard-circle-button ${
                isPlaying ? 'white-pause' : 'white-play'
              }`}
              onClick={() => togglePlay(isPlaying)}>
              <Icon name={isPlaying ? 'pause' : 'play'} />
            </div>
          </CircularProgressbarWithChildren>
        ) : (
          <div className="mpb-holder">
            <PlayWhite className="icon icon-playw" />
          </div>
        )}
      </div>
      <p className="chapter-name">{name}</p>
      <span className="chapter-length">{duration}</span>
    </div>
  );
}

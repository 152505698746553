import React from 'react';
import { Link } from "react-router-dom";

function Nav() {
  return (
    <nav className="nav">
      <div className="nav-container">
        <div className="part">
          <a href="/" className="logo">
            <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="Logo" />
          </a>
        </div>
        <div className="part">
          <div className="socials">
            <a href="#" className="social-apple">
              <img
                src={`${process.env.PUBLIC_URL}/img/socials/apple.svg`}
                alt="App Store"
              />
            </a>
            <a href="#" className="social-google">
              <img
                src={`${process.env.PUBLIC_URL}/img/socials/google.svg`}
                alt="Play Market"
              />
            </a>
          </div>
        </div>
        <div className="part">
          <div className="btns-wrap">
            <Link to="/login" className="link">
              Iniciar
            </Link>

            <Link to="/register" className="btn">
              Registrar
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;

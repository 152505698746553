import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon/Icon';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowright.svg';

class MobileHome extends Component {
  render() {
    const { name = '' } = this.props;
    return (
      <div className="mobile-menu-section">
        <div className="mobile-menu-home-header">
          <Icon name="clazbooks" className="mobile-menu-logo" />
          <div>
            <span>
              Hola{' '}
              {name
                .split(' ')
                .slice(0, 1)
                .join('')}
            </span>
          </div>
        </div>

        <div className="mobile-buttons">
          <div>
            <Link to="/logout" className="standard-button logout-button">
              <ArrowRight />
              Cerrar sesion
            </Link>
            <div className="socials-btn">
              <a
                href="https://apps.apple.com/us/app/clazbooks-libros/id1224261279"
                target="_blank"
                rel="noopener noreferrer">
                <span className="socials-btn-icon">
                  <img src={require('assets/apple.svg')} alt="" />
                </span>
                <span className="label">App Store</span>
                <span className="arrow">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 7.99998C11 7.82076 10.928 7.64156 10.7842 7.50493L6.25832 3.20514C5.97041 2.93162 5.50363 2.93162 5.21584 3.20514C4.92805 3.47855 4.92805 3.92193 5.21584 4.19548L9.22061 7.99998L5.21598 11.8045C4.92819 12.078 4.92819 12.5214 5.21598 12.7948C5.50377 13.0684 5.97055 13.0684 6.25846 12.7948L10.7844 8.49504C10.9281 8.35834 11 8.17914 11 7.99998Z"
                      fill="#1E1F20"
                    />
                  </svg>
                </span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.clazbooks.clazbooks&hl=en"
                target="_blank"
                rel="noopener noreferrer">
                <span className="socials-btn-icon">
                  <img src={require('assets/google.svg')} alt="" />
                </span>
                <span className="label">Google Play</span>
                <span className="arrow">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 7.99998C11 7.82076 10.928 7.64156 10.7842 7.50493L6.25832 3.20514C5.97041 2.93162 5.50363 2.93162 5.21584 3.20514C4.92805 3.47855 4.92805 3.92193 5.21584 4.19548L9.22061 7.99998L5.21598 11.8045C4.92819 12.078 4.92819 12.5214 5.21598 12.7948C5.50377 13.0684 5.97055 13.0684 6.25846 12.7948L10.7844 8.49504C10.9281 8.35834 11 8.17914 11 7.99998Z"
                      fill="#1E1F20"
                    />
                  </svg>
                </span>
              </a>
            </div>

            <a
              className="instagram-btn"
              href="https://www.instagram.com/clazbooks"
              target="blank"
              rel="noopener noreferrer">
              <span className="wrapper" />
              <span className="instagram-btn-icon">
                <span className="icon-wrap">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.9375 0H13.0625C16.3412 0 19 2.65881 19 5.9375V13.0625C19 16.3412 16.3412 19 13.0625 19H5.9375C2.65881 19 0 16.3412 0 13.0625V5.9375C0 2.65881 2.65881 0 5.9375 0ZM9.5 4.75C6.87681 4.75 4.75 6.87681 4.75 9.5C4.75 12.1232 6.87681 14.25 9.5 14.25C12.1232 14.25 14.25 12.1232 14.25 9.5C14.25 6.87681 12.1232 4.75 9.5 4.75ZM9.5 12.4688C7.86363 12.4688 6.53125 11.1364 6.53125 9.5C6.53125 7.86244 7.86363 6.53125 9.5 6.53125C11.1364 6.53125 12.4688 7.86244 12.4688 9.5C12.4688 11.1364 11.1364 12.4688 9.5 12.4688ZM17.2188 13.0625C17.2188 15.3544 15.3544 17.2188 13.0625 17.2188H5.9375C3.64563 17.2188 1.78125 15.3544 1.78125 13.0625V5.9375C1.78125 3.64563 3.64563 1.78125 5.9375 1.78125H13.0625C15.3544 1.78125 17.2188 3.64563 17.2188 5.9375V13.0625ZM15.2392 4.39375C15.2392 4.74331 14.9558 5.02669 14.6062 5.02669C14.2567 5.02669 13.9733 4.74331 13.9733 4.39375C13.9733 4.04419 14.2567 3.76081 14.6062 3.76081C14.9558 3.76081 15.2392 4.04419 15.2392 4.39375Z"
                      fill="url(#paint0_linear)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="10.3697"
                        y1="25.8912"
                        x2="27.6304"
                        y2="8.63036"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FFC926" />
                        <stop offset="0.438599" stopColor="#FF5346" />
                        <stop offset="1" stopColor="#C536DE" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.9375 0H13.0625C16.3412 0 19 2.65881 19 5.9375V13.0625C19 16.3412 16.3412 19 13.0625 19H5.9375C2.65881 19 0 16.3412 0 13.0625V5.9375C0 2.65881 2.65881 0 5.9375 0ZM9.5 4.75C6.87681 4.75 4.75 6.87681 4.75 9.5C4.75 12.1232 6.87681 14.25 9.5 14.25C12.1232 14.25 14.25 12.1232 14.25 9.5C14.25 6.87681 12.1232 4.75 9.5 4.75ZM9.5 12.4688C7.86363 12.4688 6.53125 11.1364 6.53125 9.5C6.53125 7.86244 7.86363 6.53125 9.5 6.53125C11.1364 6.53125 12.4688 7.86244 12.4688 9.5C12.4688 11.1364 11.1364 12.4688 9.5 12.4688ZM17.2188 13.0625C17.2188 15.3544 15.3544 17.2188 13.0625 17.2188H5.9375C3.64563 17.2188 1.78125 15.3544 1.78125 13.0625V5.9375C1.78125 3.64563 3.64563 1.78125 5.9375 1.78125H13.0625C15.3544 1.78125 17.2188 3.64563 17.2188 5.9375V13.0625ZM15.2392 4.39375C15.2392 4.74331 14.9558 5.02669 14.6062 5.02669C14.2567 5.02669 13.9733 4.74331 13.9733 4.39375C13.9733 4.04419 14.2567 3.76081 14.6062 3.76081C14.9558 3.76081 15.2392 4.04419 15.2392 4.39375Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
              </span>
              <span className="label">Instagram</span>
              <span className="arrow">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 7.99998C11 7.82076 10.928 7.64156 10.7842 7.50493L6.25832 3.20514C5.97041 2.93162 5.50363 2.93162 5.21584 3.20514C4.92805 3.47855 4.92805 3.92193 5.21584 4.19548L9.22061 7.99998L5.21598 11.8045C4.92819 12.078 4.92819 12.5214 5.21598 12.7948C5.50377 13.0684 5.97055 13.0684 6.25846 12.7948L10.7844 8.49504C10.9281 8.35834 11 8.17914 11 7.99998Z"
                    fill="#1E1F20"
                  />
                </svg>
              </span>
            </a>
            <div className="socials-btn">
              <a
                href="https://www.youtube.com/channel/UC8mVb8KPJ0ev6ZtFCZM_K6w"
                target="_blank"
                rel="noopener noreferrer">
                <span className="socials-btn-icon">
                  <img src={require('assets/youtube.svg')} alt="" />
                </span>
                <span className="label">Youtube</span>
                <span className="arrow">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 7.99998C11 7.82076 10.928 7.64156 10.7842 7.50493L6.25832 3.20514C5.97041 2.93162 5.50363 2.93162 5.21584 3.20514C4.92805 3.47855 4.92805 3.92193 5.21584 4.19548L9.22061 7.99998L5.21598 11.8045C4.92819 12.078 4.92819 12.5214 5.21598 12.7948C5.50377 13.0684 5.97055 13.0684 6.25846 12.7948L10.7844 8.49504C10.9281 8.35834 11 8.17914 11 7.99998Z"
                      fill="#1E1F20"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
          <div>
            <p>
              5201 Blue Lagoon Drive. Floor 8 Miami, FL 33126 Clazbooks 2019
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(MobileHome);

function mapStateToProps(state) {
  const user = state.authentication.user;
  const { id } = user;
  const { email, name } = user.attributes;
  return {
    email,
    name,
    id,
  };
}

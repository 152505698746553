import React from 'react';
import { connect } from 'react-redux';

import BookItem from 'components/books/BookItem';
import { bookEntitiesSelector } from 'modules/books';

const defaultObject = {};

function BookGroup({ booksEntities, books, title }) {
  const pageSize = 6;
  const [number, setNumber] = React.useState(pageSize);
  const totalBooks = books.length;
  return (
    <div className="standard-section">
      {title && <h1>{title}</h1>}
      <div className="book-card-grid">
        {books.slice(0, number).map((book, index) => {
          const bookEntity = booksEntities[book] || defaultObject;
          const bookAttibutes = bookEntity.attributes || defaultObject;
          return (
            <BookItem
              id={bookEntity.id}
              key={bookEntity.id}
              index={index}
              cover={bookAttibutes.mediumImageUrl}
              rating={1}
              title={bookAttibutes.name}
              author={bookAttibutes.authorName}
              url={`/book/${bookEntity.id}`}
              authorUrl={'/no-url'}
              favorite={bookAttibutes.favorite}
              audioDuration={bookAttibutes.audioDuration}
            />
          );
        })}
      </div>
      <div className="lm-holder">
        {number <= totalBooks && (
          <div className="load-more" onClick={() => setNumber(number + pageSize)}>
            Load More
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(BookGroup);

function mapStateToProps(state) {
  return {
    booksEntities: bookEntitiesSelector(state),
  };
}

BookGroup.defaultProps = {
  books: [],
};

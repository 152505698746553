import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { map } from 'lodash-es';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import ClickOutHandler from 'react-onclickout';

import Icon from 'components/Icon/Icon';

import { ReactComponent as Details } from '../../assets/icons/details.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowright.svg';
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as Messenger } from '../../assets/icons/messenger.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as CopyLink } from '../../assets/icons/link.svg';
import { ReactComponent as Playlists } from '../../assets/icons/playlists.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Play } from '../../assets/icons/play.svg';
import { convertTime } from 'utils/timeManipulation';
import { actions } from 'modules/player';
import { actions as bookActions } from 'modules/books';

const PlayItems = [
  'Biographies',
  'Discipline',
  'Focus',
  'Spiritual Health',
  'Management',
];

const buttonStyles = {
  border: 0,
  backgroundColor: 'transparent',
  padding: '2px',
  lineHeight: 1,
  color: 'inherit',
};

class BookItem extends Component {
  constructor(props) {
    super(props);
    this.holder = React.createRef();
    this.state = {
      openDetails: false,
      flip: false,
      openPlaylists: false,
      width: 0,
      playing: false,
      favoriting: false,
    };
  }
  toggleFavorite =(id, index) => {
    const { addToFavs, removeFromFavs, favorite } = this.props;
    const action = favorite ? removeFromFavs : addToFavs;
    this.setState({ favoriting: true });
    return action(id, index).finally(() => this.setState({ favoriting: false }));
  }
  renderBackground(cover) {
    return { backgroundImage: `url(${cover})` };
  }
  render() {
    const {
      id,
      index,
      cover,
      title,
      author,
      url,
      authorUrl,
      favorite,
      audioDuration,
      activateTrack,
    } = this.props;
    const { openDetails, flip, openPlaylists, width, playing, favoriting } = this.state;
    return (
      <div
        className={classNames('book-card-holder', { 'open-nav': openDetails })}
        ref={this.holder}>
        <div className="book-card">
          <Link
            to={url}
            className="book-card-cover"
          >
            <img src={cover} alt={title} />
          </Link>
          <div className="bc-right">
            <div className="bcr-top">
              <span className="bcr-rating">
                <button
                  type="button"
                  disabled={favoriting}
                  onClick={() => this.toggleFavorite(id, index)}
                  style={buttonStyles}>
                  <Icon name={favorite ? 'heart' : 'heart-o'} />
                </button>
              </span>
              <Link to={url} className="bcr-title-link">
                <h3 className="bcr-title">{title}</h3>
              </Link>
              <p className="bcr-author">{author}</p>
            </div>
            <div className="bcr-bottom">
              <button
                type="button"
                className={classNames('standard-circle-button play-button play-button-circle', {
                  'active-play': playing,
                })}
                onClick={() => {
                  activateTrack(id);
                }}>
                <Icon name="play" />
                <span>{convertTime(Number(audioDuration))}</span>
              </button>
              {/*<Link className="standard-circle-button read-button" to={url}>
                <Read />
                <span>12:32</span>
              </Link>*/}
            </div>
          </div>
          {/*<Details
            className="details-svg"
            onClick={() => this.setState({ openDetails: !openDetails })}
          />*/}
          {openDetails && (
            <React.Fragment>
              {width > 768 ? (
                <ClickOutHandler
                  onClickOut={() => this.setState({ openDetails: false })}>
                  <div
                    onClick={() => this.setState({ openDetails: false })}
                    className={classNames('book-card-menu', {
                      'bcm-flip': flip,
                    })}>
                    <div className="book-card-menu-top">
                      <Link to={authorUrl}>Go to Author</Link>
                      <Link to="#">Unfollow Author</Link>
                      {favorite ? (
                        <Link to="#">Remove to Favourites</Link>
                      ) : (
                        <Link to="#">Add from Favourites</Link>
                      )}
                      <div className="bcmt-content playlist-hover">
                        Add to Playlist <ArrowRight className="arrow-right" />
                        <div className="playlist-dropdown">
                          <Link to="/createplaylist" className="pd-newlist">
                            <Plus />
                            <span>New Playlist</span>
                          </Link>
                          <div className="plist-dropdown-bottom">
                            {map(PlayItems, p => (
                              <div
                                className="pd-item"
                                data-name={p}
                                key={`${p}card`}
                                onClick={() =>
                                  this.createNotification(p, title)
                                }>
                                <Playlists />
                                <span>{p}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="book-card-menu-bottom">
                      <div className="bcm-share-link">
                        Share <ArrowRight className="arrow-right" />
                        <div className="share-dropdown">
                          <div className="shd-top">
                            <a
                              href={`https://www.facebook.com/sharer/sharer.php?u=%PUBLIC_URL%${url}`}
                              target="_blank"
                              rel="noopener noreferrer">
                              <Facebook />
                              <span>Facebook</span>
                            </a>
                            <a href="#">
                              <Messenger />
                              <span>Messenger</span>
                            </a>
                            <a
                              href={`https://twitter.com/home?status=Check%20out%20TITLE%20by%20AUTHOR%20on%20Clazbooks%20at%20%PUBLIC_URL%${url}`}
                              target="_blank"
                              rel="noopener noreferrer">
                              <Twitter />
                              <span>Twitter</span>
                            </a>
                          </div>
                          <div className="shd-bottom">
                            <CopyToClipboard
                              text={`${window.location.origin}${authorUrl}`}>
                              <span>
                                <CopyLink />
                                Copy Author Link
                              </span>
                            </CopyToClipboard>
                            <CopyToClipboard
                              text={`${window.location.origin}${url}`}>
                              <span>
                                <CopyLink />
                                Copy Clazbook URL
                              </span>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ClickOutHandler>
              ) : (
                ReactDOM.createPortal(
                  <div className="book-card-menu-mobile-holder">
                    <div className="bcmmh-overlay" />
                    {openPlaylists ? (
                      <div className="bcmm">
                        <div className="bcmm-top">
                          <div
                            className="bcmm-item"
                            onClick={() =>
                              this.setState({ openPlaylists: false })
                            }>
                            <div className="bcmm-i-text">
                              <ArrowRight className="flip" />
                              <span>Back</span>
                            </div>
                          </div>
                        </div>
                        <div className="bcmm-bottom">
                          <p className="bcmm-bottom-header">My Playlists</p>
                          {map(PlayItems, p => (
                            <div
                              className="bcmm-item"
                              data-name={p}
                              key={`${p}card`}
                              onClick={() => {
                                this.createNotification(p, title);
                                this.setState({
                                  openDetails: false,
                                  openPlaylists: false,
                                });
                              }}>
                              <div className="bcmm-i-text">
                                <Playlists />
                                <span>{p}</span>
                              </div>
                            </div>
                          ))}
                          <div className="bcmm-close">
                            <div
                              className="bcmm-close-button standard-button"
                              onClick={() =>
                                this.setState({
                                  openDetails: false,
                                  openPlaylists: false,
                                })
                              }>
                              Close
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="bcmm">
                        <div className="bcmm-top">
                          <div
                            className="bcmm-item"
                            onClick={() =>
                              this.setState({ openPlaylists: true })
                            }>
                            <div className="bcmm-i-text">
                              <Playlists />
                              <span>Add to Playlist</span>
                            </div>
                            <ArrowRight />
                          </div>
                        </div>
                        <div className="bcmm-bottom">
                          <p className="bcmm-bottom-header">Share Book</p>
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=%PUBLIC_URL%${url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bcmm-item">
                            <div className="bcmm-i-text">
                              <Facebook />
                              <span>Share with Facebook</span>
                            </div>
                            <ArrowRight />
                          </a>
                          <a
                            href="/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bcmm-item">
                            <div className="bcmm-i-text">
                              <Messenger />
                              <span>Share with Messenger</span>
                            </div>
                            <ArrowRight />
                          </a>
                          <a
                            href={`https://twitter.com/home?status=Check%20out%20TITLE%20by%20AUTHOR%20on%20Clazbooks%20at%20%PUBLIC_URL%${url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bcmm-item">
                            <div className="bcmm-i-text">
                              <Twitter />
                              <span>Share with Twitter</span>
                            </div>
                            <ArrowRight />
                          </a>
                          <div className="bcmm-close">
                            <div
                              className="bcmm-close-button standard-button"
                              onClick={() =>
                                this.setState({ openDetails: false })
                              }>
                              Close
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>,
                  document.getElementById('app-root')
                )
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

BookItem.propTypes = {
  book: PropTypes.object,
};

export default connect(
  null,
  {
    activateTrack: actions.activateTrack,
    addToFavs: bookActions.addToFavs,
    removeFromFavs: bookActions.removeFromFavs,
  }
)(BookItem);

import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function PrivateRoute({ component: Component, accessToken, expiry, ...rest }) {
  const now = Date.now();
  const isLoggedIn = Boolean(accessToken) && now < expiry * 1000;
  return (
    <Route
      {...rest}
      render={props => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

export default connect(mapStateToProps)(PrivateRoute);

function mapStateToProps(state) {
  return {
    accessToken: state.authentication.accessToken,
    expiry: state.authentication.expiry,
  };
}

import { createReducer } from 'redux-starter-kit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import path from 'ramda/es/path';
import { networkStatus } from 'utils/constants';
import {
  getCategories as _getCategories,
  getCategory as _getCategory,
} from './api';
import { tranformRelationships } from 'utils/entitites';

export const categoriesInitialState = {
  getCategoriesStatus: networkStatus.notAsked,
  categoryStatus: {},
  list: [],
  entities: {},
};

const getCategories = createAsyncAction('GET_CATEGORIES', _getCategories);
const getCategory = createAsyncAction('GET_CATEGORY', _getCategory, id => ({
  extract: ['books'],
  id,
}));

const reducer = createReducer(categoriesInitialState, {
  [String(getCategories.pending)]: state => {
    state.getCategoriesStatus = networkStatus.pending;
  },
  [String(getCategories.fulfilled)]: (state, action) => {
    const { payload } = action;
    state.getCategoriesStatus = networkStatus.fulfilled;
    state.list = payload.result;
    state.entities = payload.entities.categories;
  },
  [String(getCategories.rejected)]: state => {
    state.getCategoriesStatus = networkStatus.reject;
  },
  [String(getCategory.pending)]: (state, action) => {
    const {
      meta: { id: categoryId },
    } = action;
    state.categoryStatus[categoryId] = networkStatus.pending;
  },
  [String(getCategory.fulfilled)]: (state, action) => {
    const {
      meta: { id: categoryId },
    } = action;
    state.categoryStatus[categoryId] = networkStatus.fulfilled;
  },
  [String(getCategory.rejected)]: (state, action) => {
    const {
      meta: { id: categoryId },
    } = action;
    state.categoryStatus[categoryId] = networkStatus.rejected;
  },
  GET_ENTITIES: (state, action) => {
    if (action.payload.categories) {
      Object.assign(state.entities, action.payload.categories);
    }
  },
});

export default reducer;

export const actions = {
  getCategories,
  getCategory,
};

const categoriesListSelector = path(['categories', 'list']);

const booksByCategoryListSelector = path(['categories', 'booksByCategory']);

export const catagoriesEntitiesSelector = path(['categories', 'entities']);

export const categoryStatusSelector = (state, id) =>
  path(['categories', 'categoryStatus'], state)[id] || networkStatus.notAsked;

export const booksIdsByCategoryIdSelector = (state, id) =>
  booksByCategoryListSelector(state)[id] || [];

export const categoriesSelector = createSelector(
  [catagoriesEntitiesSelector, categoriesListSelector],
  (entities, list) => list.map(id => entities[id])
);

const _getCategoryById = (state, id) =>
  catagoriesEntitiesSelector(state)[id] || [];

export const getCategoryById = createCachedSelector(_getCategoryById, entity =>
  tranformRelationships(entity)
)((_, id) => id);

import isEmpty from 'ramda/es/isEmpty';
import pathOr from 'ramda/es/pathOr';
import pick from 'ramda/es/pick';

export default function entitiesMiddleware() {
  const $entitiesMiddleware = function $entitiesMiddleware({ dispatch }) {
    return function $$entitiesMiddleware(next) {
      return function $$$entitiesMiddleware(action) {
        const extract = pathOr([], ['meta', 'extract'], action);
        const entities = pathOr({}, ['payload', 'entities'], action);
        if (
          typeof extract !== 'undefined' &&
          Array.isArray(extract) &&
          typeof entities !== 'undefined' &&
          !isEmpty(entities)
        ) {
          dispatch({ type: 'GET_ENTITIES', payload: pick(extract, entities) });
        }
        next(action);
      };
    };
  };
  return $entitiesMiddleware;
}

import React from 'react';
import { Link } from "react-router-dom";

export default function MobileNav() {
  return (
    <nav className="nav-mobile">
      <div className="nav-container">
        <a href="/" className="logo">
          <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="Logo" />
        </a>
        <div className="btns-wrap">
          <Link to="/login" className="link">Iniciar</Link>
          <Link to="/register" className="btn">Registrar</Link>
        </div>
      </div>
    </nav>
  );
}

import React, { Component } from "react";

import { ReactComponent as ArrowRight } from "../../assets/icons/arrowright.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import profilephoto from "../../assets/samples/profilephoto.png";

class DeleteAccountSection extends Component {
  constructor(props) {
    super(props);
    this.state = { sure: false };
  }
  render() {
    const { sure } = this.state;
    const { close } = this.props;
    return (
      <div className="book-card-menu-mobile-holder">
        <div className="bcmmh-overlay" />
        {sure ? (
          <div className="bcmm">
            <div className="bcmm-bottom delete-sure">
              <h2>Do you really want to delete your account?</h2>
              <div className="delete-bar">
                <img src={profilephoto} alt="" />
                Maria Chapman
              </div>
              <div className="delete-buttons">
                <div
                  className="standard-button"
                  onClick={() => this.setState({ sure: false })}
                >
                  <ArrowRight className="flip" />Back
                </div>
                <div className="standard-button">
                  <Delete />Delete
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bcmm">
            <div className="bcmm-top">
              <div className="bcmm-item">
                <div
                  className="bcmm-i-text delete-i"
                  onClick={() => this.setState({ sure: true })}
                >
                  <Delete />
                  <span>Delete My Account</span>
                </div>
              </div>
            </div>
            <div className="bcmm-bottom">
              <div className="bcmm-close">
                <div
                  className="bcmm-close-button standard-button"
                  onClick={close}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DeleteAccountSection;
